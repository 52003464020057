<script>
import MapView from '@/components/MapView'
import { Button, Tree, Modal, message, Select } from 'ant-design-vue'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import FormView from '@/components/FormView'
import { deleteAction, getAction, postAction } from '../../command/netTool'
import TableButton from '@/components/TableButton'

export default {
  name: 'map',
  data() {
    return {
      // 当前头部选择
      active: 0,
      // 显示弹窗
      showModal: false,
      // 乡镇数据
      townsData: [],
      // 地块树
      treeData: [],
      // 当前新建选择地块
      dkInfo: null,
      // 当前树形的选择
      checkedKeys: [],
      // modal表单数据源
      modalForm: null,
      // 编辑模式弹窗
      showEditModal: false,
      // 编辑弹窗的模式 edit/query
      editMode: false,
    }
  },
  mounted() {
    // 获取街道数据
    getAction('/api/dataoperation/region/freeTree?id=330282000000&level=5').then(
      (e) => (this.townsData = e.map((e) => ({ name: e.name, value: e.id })))
    )
    this.getTree()
    // this.dkInfo = {}
    // this.$refs._mapView.setMode('create')
  },
  computed: {
    categoryArr() {
      return this.$store.state.categoryArr
    },
  },
  watch: {
    showEditModal(newValue) {
      if (!newValue) {
        this.modalForm = null
      }
    },
    active(newValue) {
      this.dkInfo = null
    },
  },
  methods: {
    getTree() {
      // 获取地块树列表
      getAction('/api/dataoperation/collectionMainDk/findTree').then((e) => {
        this.treeData = [
          {
            name: '乡镇/街道',
            children: e.regionDkVoList,
          },
          ...e.categoryList,
        ]
      })
    },
    getHeaderData() {
      return [
        {
          name: '地图',
        },
        {
          name: '表格',
        },
      ]
    },
    renderHeader() {
      const data = this.getHeaderData()
      return (
        <div class="map-header">
          {data.map((e, i) => {
            return (
              <div class="tabs-button" data-active={this.active == i} onClick={() => (this.active = i)}>
                {e.name}
              </div>
            )
          })}
        </div>
      )
    },
    renderNew() {
      return (
        <Button
          onClick={() => {
            this.modalForm = null
            this.showModal = true
          }}
          type="dashed"
          style={{ marginLeft: '10px', marginRight: '10px', height: '35px', minHeight: '35px' }}
        >
          新建地块
        </Button>
      )
    },
    onTreeCheck(checkedKeys, info) {
      this.checkedKeys = info.checkedNodes
    },
    renderTreeView() {
      return (
        <Tree
          checkable={true}
          onCheck={this.onTreeCheck}
          treeData={this.treeData}
          replaceFields={{
            title: 'name',
            children: 'children',
            key: 'id',
          }}
          defaultExpandAll={true}
        ></Tree>
      )
    },
    renderTree() {
      return (
        <div class="map-tree">
          {/* 新建地块 */}
          {this.renderNew()}
          {/* 树形结构 */}
          {this.renderTreeView()}
        </div>
      )
    },
    onModalChange({ state, data = null } = {}) {
      this.modalForm = data
      this.showEditModal = state
    },
    renderMapView() {
      return (
        <MapView
          dkInfo={this.dkInfo}
          ref={'_mapView'}
          checkedKeys={this.checkedKeys}
          onSaveSuccess={this.getTree}
          onModalChange={this.onModalChange}
        />
      )
    },
    renderMapBody() {
      return (
        <div class="map-body">
          {this.renderTree()}
          {this.renderMapView()}
        </div>
      )
    },
    deleteDK(id) {
      deleteAction('/api/dataoperation/collectionMainDk/delDk', {
        ids: [id],
      })
        .then(() => {
          message.success('删除成功')
          this.$refs.table.reset()
        })
        .catch(() => {
          message.error('删除失败')
        })
    },
    getCenterPoint(records) {
      return turf.center(JSON.parse(records.dkLocation).features[0]).geometry.coordinates
    },
    getColumns() {
      return [
        {
          dataIndex: 'dkName',
          title: '地块名称',
        },
        {
          dataIndex: 'phone',
          title: '法人/手机号',
          render: ({ records }) => {
            return (records.personName || '') + '/' + (records.linkPhone || '')
          },
        },
        {
          dataIndex: 'mainBodyName',
          title: '主体名称',
        },
        {
          dataIndex: 'name',
          title: '主体类型',
        },
        {
          dataIndex: 'streetName',
          title: '所在乡镇',
        },
        {
          dataIndex: 'dkArea',
          title: '土地面积(亩)',
        },
        {
          dataIndex: 'a7',
          title: '操作',
          render: ({ records }) => {
            const data = [
              {
                name: '查看',
                display: true,
                onClick: () => {
                  this.active = 0
                  this.$nextTick(() => {
                    this.$refs['_mapView'].setCenter(this.getCenterPoint(records))
                  })
                },
              },
              {
                name: '编辑',
                display: true,
                onClick: () => {
                  this.showModal = true
                  this.modalForm = { ...records, dkArea: String(records.dkArea) }
                },
              },
              {
                name: '删除',
                display: true,
                onClick: () => {
                  this.deleteDK(records.id)
                },
              },
            ]
            return <TableButton data={data} />
          },
        },
      ]
    },
    getFormData() {
      return [
        {
          type: 'row',
          cols: 24,
          rules: [
            {
              required: true,
            },
          ],
          gutter: 10,
          children: [
            {
              type: 'dropDownInput',
              cols: 22,
              key: 'name',
              valueKey: 'mainBodyId',
              inputProps: {
                type: 'InputSearch',
              },
              rules: [
                {
                  required: true,
                  message: '请输入内容',
                },
              ],
            },
            {
              type: 'button',
              label: '添加',
              cols: 2,
              onClick: () => {
                window.open('/basicInformation/detail')
              },
              props: {
                type: 'primary',
              },
            },
          ],
        },
        {
          type: 'input',
          name: '地块名称',
          key: 'dkName',
          rules: [
            {
              required: true,
              message: '请输入地块名称',
            },
          ],
        },
        // {
        //   type: 'colorPick',
        //   name: '地块颜色',
        //   key: 'dkColor',
        //   rules: [
        //     {
        //       required: true,
        //       message: '请输入地块颜色',
        //     },
        //   ],
        // },
        // {
        //   name: '所在乡镇',
        //   type: 'select',
        //   key: 'streetCode',
        //   typeData: this.townsData,
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        // },
        // {
        //   name: '土地面积(亩)',
        //   type: 'input',
        //   key: 'dkArea',
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        // },
      ]
    },
    getHeader() {
      return [
        {
          name: '名称',
          type: 'input',
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
          key: 'dkName',
        },
        {
          name: '主体类型',
          type: 'treeSelect',
          key: 'name',
          typeData: this.categoryArr,
        },
        {
          name: '所在乡镇',
          type: 'select',
          key: 'streetCode',
          typeData: this.townsData,
        },
        {
          name: '法人',
          type: 'input',
          key: 'personName',
        },
        {
          name: '手机号',
          type: 'input',
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
          key: 'linkPhone',
        },
        {
          name: '主体名称',
          type: 'input',
          key: 'mainBodyName',
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          onClick: () => {
            this.showModal = true
          },
        },
      ]
    },
    renderTableBody() {
      return (
        <div class="map-table-body">
          <TemplateHeaderTable
            url={'/api/dataoperation/collectionMainDk/page'}
            tableColumns={this.getColumns()}
            headerData={this.getHeader()}
            button={this.getButton()}
            ref={'table'}
          />
        </div>
      )
    },
    onModalSuccess(data) {
      if (data.id) {
        const json = JSON.parse(this.modalForm.dkLocation)
        const features = json.features.map((e) => {
          delete e.properties.dkLocation
          delete data.dkLocation
          return {
            ...e,
            properties: {
              ...e.properties,
              ...data,
            },
          }
        })
        postAction('/api/dataoperation/collectionMainDk/updateDk', {
          type: 'FeatureCollection',
          features,
        })
          .then(() => {
            this.showModal = false
            this.modalForm = null
            message.success('编辑成功')
            this.$refs.table.reset()
          })
          .catch(() => {
            message.error('编辑失败')
          })
      } else {
        this.showModal = false
        this.modalForm = null
        this.$refs['_mapView'].setZTInfo(data)
      }
    },
    renderModal() {
      return (
        <Modal
          visible={true}
          width={'635px'}
          title={this.modalForm ? '编辑地块' : '新建地块'}
          onCancel={() => (this.showModal = false)}
          onOk={() => this.$refs['_modalForm'].sumbit()}
        >
          <FormView
            data={this.getFormData()}
            form={this.modalForm}
            cols={24}
            ref="_modalForm"
            onSuccess={this.onModalSuccess}
          />
        </Modal>
      )
    },
    renderModalInput(item) {
      return (
        <div>
          <input style={{ height: '80%' }} v-model={this.modalForm[item.key]} />
        </div>
      )
    },
    renderModalSelect() {
      return (
        <Select
          style={{ width: '200px' }}
          value={this.modalForm.streetName}
          onChange={(data) => {
            this.modalForm.streetName = this.townsData.find((e) => e.value == data).name
            this.modalForm.streetCode = data
          }}
        >
          {this.townsData.map((e) => {
            return <Select.Option value={e.value}>{e.name}</Select.Option>
          })}
        </Select>
      )
    },
    renderModalFormView(item) {
      switch (item.type) {
        case 'input':
          return this.renderModalInput(item)
        default:
          return this.renderModalSelect(item)
      }
    },
    renderModalTable(data) {
      return (
        <div class="modal-table">
          {data.map((e) => {
            return (
              <div>
                <span>{e.name}</span>
                {this.editMode && !e.disabled ? this.renderModalFormView(e) : <span>{e.value}</span>}
              </div>
            )
          })}
        </div>
      )
    },
    onModalConfirm() {
      const lastSelect = this.getSelectLastData()
      if (lastSelect) {
        this.deleteLayer(lastSelect)
      }
      this.showEditModal = false
      this.modalForm = null
    },
    renderEditModalFoot() {
      const data = [
        {
          icon: <a-icon type="edit" style={{ fontSize: '23px' }} onClick={() => (this.editMode = !this.editMode)} />,
        },
        {
          icon: (
            <a-popconfirm title="确认删除吗?" ok-text="确认" cancel-text="取消" onConfirm={this.onModalConfirm}>
              <a-icon type="delete" style={{ fontSize: '23px' }} />
            </a-popconfirm>
          ),
        },
      ]
      return (
        <div class="edit-modal-foot">
          {data.map((e) => {
            return <span style={{ marginLeft: '15px' }}>{e.icon}</span>
          })}
        </div>
      )
    },
    renderEditModal() {
      const data = [
        {
          name: '地块名称',
          value: this.modalForm.dkName,
          key: 'dkName',
          type: 'input',
        },
        {
          name: '乡镇',
          value: this.modalForm.streetName,
          type: 'select',
          key: 'streetName',
        },
        // {
        //   name: '面积',
        //   value: this.modalForm.dkArea,
        //   type: 'input',
        //   key: 'dkArea',
        // },
        {
          name: '创建人',
          disabled: true,
          value: this.modalForm.createBy,
        },
        {
          name: '创建时间',
          value: this.modalForm.createTime,
          disabled: true,
        },
        {
          name: '更新人',
          disabled: true,
          value: this.modalForm.updateBy,
        },
        {
          name: '更新时间',
          value: this.modalForm.updateTime,
          disabled: true,
        },
      ]
      return (
        <div class="edit-modal">
          <div style={{ padding: '20px' }}>
            <div style={{ fontWeight: 500 }}>{this.modalForm.mainBodyName}</div>
            <div class="edit-modal-close" onClick={() => (this.showEditModal = false)}>
              <a-icon type="close" />
            </div>
            {this.renderModalTable(data)}
          </div>
          {this.renderEditModalFoot()}
        </div>
      )
    },
  },
  render() {
    return (
      <div class="map-main">
        {this.renderHeader()}
        {this.active == 0 ? this.renderMapBody() : this.renderTableBody()}
        {this.showModal && this.renderModal()}
        {this.showEditModal && this.renderEditModal()}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.map-main {
  margin: -24px;
  height: calc(100vh - 90px);
  max-height: calc(100vh - 90px);
  position: relative;
}
.map-header {
  height: 65px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabs-button {
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #515a6e;
  cursor: pointer;
  &[data-active='true'] {
    color: white;
    background-color: rgba(38, 101, 250, 1);
    border-radius: 4px;
  }
}
.map-body {
  display: flex;
  height: 100%;
  // height: calc(100vh - (65px * 2));
  // max-height: calc(100vh - (65px * 2));
}
.map-tree {
  display: flex;
  flex-direction: column;
  width: 285px;
  min-width: 285px;
  height: 100%;
  background: white;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
  }
}
.map-table-body {
  margin: 24px;
}
.edit-modal {
  position: absolute;
  right: 30px;
  bottom: 40px;
  width: 300px;
  z-index: 999;
  background-color: white;
  border-radius: 8px;
}
.edit-modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.modal-table {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  /deep/.ant-select-selection.ant-select-selection--single {
    width: 100%;
  }
}
.modal-table > div {
  display: flex;
  height: 35px;
  border: 1px solid rgba(243, 243, 243, 1);
  &:nth-child(2n + 1) {
    background-color: rgb(249, 249, 249);
  }
  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    white-space: nowrap;
  }
  div {
    display: flex;
    align-items: center;
    margin-left: -10px;
    width: calc(200px + 5px);
    height: 100%;
  }
  input {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    min-height: 100%;
    padding-left: 10px;
  }
}
.modal-table > div > span:first-child {
  width: 90px;
  text-align: right;
  margin-right: 10px;
  border-right: 1px solid rgba(243, 243, 243, 1);
  color: rgb(181, 181, 181);
}
.modal-table > div > span:last-child {
  width: 200px;
  color: rgb(85, 85, 85);
}
.edit-modal-foot {
  border-width: 0px;
  width: 100%;
  height: 45px;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  // 向上的阴影
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  * {
    cursor: pointer;
  }
}
</style>
